import { useEffect, useState } from "react";
import {Container, Nav, Navbar} from "react-bootstrap";
import navicon1 from '../assets/img/nav-icon1.svg';
import navicon2 from '../assets/img/white_github.png';
import navicon3 from '../assets/img/nav-icon3.svg';


export const NavBar = () => {
    const [activeLink, setActiveLink] = useState('home');
    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        const onScroll = () => {
            if (window.scrollY > 50) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        }
        window.addEventListener("scroll", onScroll);
        return () => window.removeEventListener("scroll", onScroll);

    }, [])

    const onUpdateActiveLink = (value) => {
        setActiveLink(value);
    }

    return (
        <Navbar expand="lg" className={scrolled ? "scrolled": ""}>
            <Container>
                <Navbar.Toggle aria-controls="basic-navbar-nav">
                    <span className="navbar-toggler-icon"></span>
                </Navbar.Toggle>
                <Navbar.Collapse id="basic-navbar-nav">
                    <h1>{'<Eyad>'}</h1>
                    <Nav className="ms-auto">
                        <Nav.Link href="#home" className={activeLink === 'home' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('home')}>Home</Nav.Link>
                        <Nav.Link href="#skills" className={activeLink === 'skills' ? 'active navbar-link' : 'navbar-link'}  onClick={() => onUpdateActiveLink('skills')}>Skills</Nav.Link>
                        <Nav.Link href="#project" className={activeLink === 'project' ? 'active navbar-link' : 'navbar-link'}  onClick={() => onUpdateActiveLink('project')}>Projects</Nav.Link>
                        <Nav.Link href="https://github.com/EyadElghobary/Resume" className={activeLink === 'resume' ? 'active navbar-link' : 'navbar-link'} target="_blank">Resume</Nav.Link>
                    </Nav>
                    <span className="navbar-text">
                        <div className="social-icon">
                            <a href="https://www.linkedin.com/in/eyad-elghobary-b96486226/" target="_blank"><img src={navicon1} alt="" /></a>
                            <a href="https://github.com/EyadElghobary" target="_blank" className="git"><img src={navicon2} alt="" /></a>
                            <a href="https://www.instagram.com/eyadelghobary" target="_blank"><img src={navicon3} alt="" /></a>
                        </div>
                        <Nav.Link href="#connect">
                            <button className="vvd"><span>Let's Connect</span></button>
                        </Nav.Link>
                    </span>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}