import "react-multi-carousel/lib/styles.css";
import { Container, Row, Col } from "react-bootstrap";
import colorSharp2 from "../assets/img/color-sharp2.png";
/* --- Front End Pics --- */
import angularPic from "../assets/img/angular.svg";
import gitPic from "../assets/img/githubSkills.svg";
import JSPic from "../assets/img/javascript.svg";
import ReactPic from "../assets/img/react.svg";
/* --- Back End Pics --- */
import JavaPic from "../assets/img/java.svg";
import CPic from "../assets/img/c.svg";
import SqlPic from "../assets/img/sql-file.svg";
import PyTorchPic from "../assets/img/pytorch-icon.svg";
import SpringPic from "../assets/img/spring.svg";
/* --- Machine Learning Pic --- */
import TFPic from "../assets/img/tensor-flow.svg";
import PythonPic from "../assets/img/python.svg";
/* --- Cross Platform Pic --- */
import FlutterPic from "../assets/img/flutter.svg";


export const Skills = () => {

    

    function reveal() {
        var reveals = document.querySelectorAll(".reveal");
        for (var i = 0; i < reveals.length; i++) {
          var windowHeight = window.innerHeight;
          var elementTop = reveals[i].getBoundingClientRect().top;
          var elementVisible = 150;
          if (elementTop < windowHeight - elementVisible) {
            reveals[i].classList.add("active");
          } else {
            reveals[i].classList.remove("active");
          }
        }
    }

    window.addEventListener("scroll", reveal);

    return (
        /* className and id are switched around here temporarily to maintain CSS design */
        <section className="skills" id="skills">
            <Container className="skills-bx">
                <Row>
                    <Col>
                    <h2>Skills</h2>
                    <p>Some of my notable Skills</p>
                    </Col>
                </Row>
                <Row className="reveal fade-left">
                    <Col>
                        <Row>
                            <Row>
                                <Col>
                                <h3> Front-End</h3>
                                </Col>
                            </Row>
                            <br></br>
                            <br></br>
                            <br></br>
                            <Col>
                                <img src={angularPic} alt="Angular" />
                                <br></br>
                                <br></br>
                                <h4>Angular</h4>
                            </Col>
                            <Col>
                                <img src={gitPic} alt="GitPic" className="git"/>
                                <br></br>
                                <br></br>
                                <h4>Git</h4>
                            </Col>
                            <Col>
                                <img src={JSPic} alt="JavaScript Pic" />
                                <br></br>
                                <br></br>
                                <h4>JavaScript</h4>
                            </Col>
                            <Col>
                                <img src={ReactPic} alt="React Pic" />
                                <br></br>
                                <br></br>
                                <h4>React.Js</h4>
                            </Col>
                        </Row>
                        <br></br>
                    </Col>
                </Row>

                <br></br>
                <br></br>
                <br></br>

                <Row className="reveal fade-right">
                    <Col>
                        <Row>
                            <Row>
                                <Col>
                                <h3> Back-End</h3>
                                </Col>
                            </Row>
                            <br></br>
                            <br></br>
                            <br></br>
                            <Col>
                                <img src={CPic} alt="C Pic" />
                                <br></br>
                                <br></br>
                                <h4>C/C++</h4>
                            </Col>
                            <Col>
                                <img src={JavaPic} alt="Java Pic" />
                                <br></br>
                                <br></br>
                                <h4>Java</h4>
                            </Col>
                            <Col>
                                <img src={SqlPic} alt="Sq; Pic" />
                                <br></br>
                                <br></br>
                                <h4>SQL</h4>
                            </Col>
                            <Col>
                                <img src={SpringPic} alt="SpringBoot Pic" />
                                <br></br>
                                <br></br>
                                <h4>Spring Boot</h4>
                            </Col>
                            <Col>
                                <img src={PythonPic} alt="Python Pic" />
                                <br></br>
                                <br></br>
                                <h4>Python</h4>
                            </Col>
                        </Row>
                        <br></br>
                    </Col>
                </Row>

                <br></br>
                <br></br>
                <br></br>
                <br></br>


                <Row>
                    <Col className="reveal fade-left">
                        <Row>
                            <Row>
                                <Col>
                                <h3> Machine Learning</h3>
                                </Col>
                            </Row>
                            <br></br>
                            <br></br>
                            <br></br>
                            <Col>
                                <img src={PyTorchPic} alt="PyTorch Pic" />
                                <br></br>
                                <br></br>
                                <h4>PyTorch</h4>
                            </Col>
                            <Col>
                                <img src={TFPic} alt="GitPic" className="Tensor Flow Pic"/>
                                <br></br>
                                <br></br>
                                <h4>Tensor Flow</h4>
                            </Col>
                        </Row>
                        <br></br>
                    </Col>
                    <Col className="reveal fade-right">
                        <Row>
                            <Row>
                                <Col>
                                <h3>Cross-Platform</h3>
                                </Col>
                            </Row>
                            <br></br>
                            <br></br>
                            <br></br>
                            <Col>
                            <img src={FlutterPic} alt="Flutter Pic" className="flutter"/>
                                <br></br>
                                <br></br>
                                <h4>Flutter</h4>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
            <img className="background-image-right" src={colorSharp2}></img>
        </section>

        /* ---- */ 
    )

}
