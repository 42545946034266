import { Col , Row } from "react-bootstrap";
import projImg1 from "../assets/img/ImageCompressor.png";
import projImg2 from "../assets/img/cli.jpg";
import projImg3 from "../assets/img/MarineWay.jpg";
import projImg4 from "../assets/img/Ejada.jpg";
import projImg5 from "../assets/img/ITS.jpg";
import colorSharp2 from "../assets/img/color-sharp2.png";

export const Projects = () => {

    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    function reveal() {
        var reveals = document.querySelectorAll(".reveal");
        for (var i = 0; i < reveals.length; i++) {
          var windowHeight = window.innerHeight;
          var elementTop = reveals[i].getBoundingClientRect().top;
          var elementVisible = 150;
          if (elementTop < windowHeight - elementVisible) {
            reveals[i].classList.add("active");
          } else {
            reveals[i].classList.remove("active");
          }
        }
    }

    window.addEventListener("scroll", reveal);

    return (
        <section className="project" id="project">
            <div className="container">
                <h2>Related Projects</h2>
                <p>These are some of the projects I have been able to complete!</p>
                <Row className="reveal appear">
                    <Col>
                        <img src={projImg3} className="proj-imgbx" />
                    </Col>
                    <Col className="right">
                        <h3>Marine Way</h3>
                        <p>An Android mobile application that works a little like an online marketplace, allowing Users that have lost their items by water bodies to search for the items on the application.</p>
                        <p className="language">Stack Used: Django, React Native, MongoDB, AWS, Docker</p>
                        <a href="https://github.com/csc301-fall-2022/team-project-27-Oceanic-Impact-Inc-M" className="visit" target="_blank">Visit</a>
                    </Col>
                </Row>
                <br></br>
                <br></br>
                <Row className="reveal appear">
                    <Col className="left">
                        <h3>ITS Computer Systems Front Page</h3>
                        <p>A fun interactive front page for the FinTech company, ITS. The front page is an update to their old front page. </p>
                        <p className="language">Stack Used: HTML, CSS, JavaScript</p>
                        <Row>
                            <Col><a href="https://github.com/EyadElghobary/ITS-Front-Page" className="visit" target="_blank">Visit</a></Col>
                            <Col><a href="https://its-ksa.com" className="visit">Visit Website</a></Col>
                        </Row>
                    </Col>
                    <Col>
                        <img src={projImg5} className="proj-imgbx img-right"/>
                    </Col>
                </Row>
                <br></br>
                <br></br>
                <Row className="reveal appear">
                    <Col>
                        <img src={projImg4} className="proj-imgbx"/>
                    </Col>
                    <Col className="right">
                        <h3>Intern Program</h3>
                        <p>An Intern Management Program that allows for applying interns to be organized and managed by a given company.</p>
                        <p className="language">Stack Used: Java, SQL, Hibernate, Spring, Angular</p>
                        <a href="https://github.com/EyadElghobary/Intern-Management-System" className="visit" target="_blank">Visit</a>
                    </Col>
                </Row>
                <br></br>
                <br></br>
                <Row className="reveal appear">
                    <Col className="left">
                        <h3>Image Compressor/Decompressor Application</h3>
                        <p>A simple application that takes an image and can either compress or decompress it. This application also allows for an additional feature of mirroring.</p>
                        <p className="language">Stack Used: Python</p>
                        <a href="https://github.com/EyadElghobary/ImageCompresDecompress" className="visit" target="_blank">Visit</a>
                    </Col>
                    <Col>
                        <img src={projImg1} className="proj-imgbx img-right"/>
                    </Col>
                </Row>
                <br></br>
                <br></br>
                <Row className="reveal appear">
                    <Col>
                        <img src={projImg2} className="proj-imgbx"/>
                    </Col> 
                    <Col className="right">
                        <h3>Command Line Interface</h3>
                        <p>A Command Line that supports functionality of all bash commands. The CLI also allows for extra features for some of the commands such as ls, echo, and cat.</p>
                        <p className="language">Stack Used: C</p>
                        <a href="https://github.com/EyadElghobary/Command-Line-Interface" className="visit" target="_blank">Visit</a>
                    </Col>
                </Row>
                <br></br>
                <br></br>
            </div>
            <img className="background-image-left" src={colorSharp2} alt="Image" />
        </section>
    )
}


/*

return (
        <section className="project" id="project">
            <div className="container">
                <h2>Related Projects</h2>
                <p>These are some of the projects I have been able to complete!</p>
                <Row className="reveal appear">
                    <Col>
                        <img src={projImg5} className="proj-imgbx img-left"/>
                    </Col>
                    <Col className="right">
                        <h3>ITS Computer Systems Front Page</h3>
                        <p>A fun interactive front page for the FinTech company, ITS. The front page is an update to their old front page. </p>
                        <p className="language">Languages Used: HTML, CSS, JavaScript</p>
                        <Row>
                            <Col><a href="https://github.com/EyadElghobary/ITS-Front-Page" className="visit" target="_blank">Visit</a></Col>
                            <Col><a href="https://its-ksa.com" className="visit">Visit Website</a></Col>
                        </Row>
                    </Col>
                </Row>
                <br></br>
                <br></br>
                <Row className="reveal appear">
                    <Col className="left">
                        <h3>Intern Program</h3>
                        <p>An Intern Management Program that allows for applying interns to be organized and managed by a given company.</p>
                        <p className="language">Languages Used: Java, SQL, Hibernate, Spring, Angular</p>
                        <a href="https://github.com/EyadElghobary/Intern-Management-System" className="visit" target="_blank">Visit</a>
                    </Col>
                    <Col>
                        <img src={projImg4} className="proj-imgbx img-right"/>
                    </Col>
                </Row>
                <br></br>
                <br></br>
                <Row className="reveal appear">
                    <Col>
                        <img src={projImg1} className="proj-imgbx"/>
                    </Col>
                    <Col className="right">
                        <h3>Image Compressor/Decompressor Application</h3>
                        <p>A simple application that takes an image and can either compress or decompress it. This application also allows for an additional feature of mirroring.</p>
                        <p className="language">Languages Used: Python</p>
                        <a href="https://github.com/EyadElghobary/ImageCompresDecompress" className="visit" target="_blank">Visit</a>
                    </Col>
                </Row>
                <br></br>
                <br></br>
                <Row className="reveal appear"> 
                    <Col className="left">
                        <h3>Command Line Interface</h3>
                        <p>A Command Line that supports functionality of all bash commands. The CLI also allows for extra features for some of the commands such as ls, echo, and cat.</p>
                        <p className="language">Languages Used: C</p>
                        <a href="https://github.com/EyadElghobary/Command-Line-Interface" className="visit" target="_blank">Visit</a>
                    </Col>
                    <Col>
                        <img src={projImg2} className="proj-imgbx img-right"/>
                    </Col>
                </Row>
                <br></br>
                <br></br>
                <Row className="reveal appear">
                    <Col>
                        <img src={projImg3} className="proj-imgbx" />
                    </Col>
                    <Col className="right">
                        <h3>Advanced Simon Says</h3>
                        <p>The traditional Simon Says game that we all know but with increasing difficulty. This time speed at which lights appear is faster and less and patterns of colours increase in lengthn too.</p>
                        <p className="language">Languages Used: Assembly</p>
                        <a href="https://github.com/EyadElghobary/AdvancedSimonSays" className="visit" target="_blank">Visit</a>
                    </Col>
                </Row>
            </div>
            <img className="background-image-left" src={colorSharp2} alt="Image" />
        </section>
    )
}

*/